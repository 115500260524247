import appStrings from '@/app/utility/string.utility';
// import URL_UTILITY from '@/app/utility/url.utility';
import { required } from 'vuelidate/lib/validators';
import commonHelper from '@/app/utility/common.helper.utility';
import addEmailSetup from './addEmailSetup';

export default {
  name: 'emailSetupType',
  components: { addEmailSetup },
  data() {
    return {
      unsubscribe: null,
      loading: false,
      showValueSetModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      defaultValue: {
        value: null,
        text: null
      },
      emailSetupType: {
        value: 'PAN_NUMBER',
        text: 'Pan Number'
      },
      legalEntity: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      vendorName: null,
      panNumber: null,
      gstNumber: null,
      currentPage: 1,
      activeFlag: true,
      vendorEmailData: [],
      vendorEmailFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        {
          key: 'vendor_comm_type_id',
          label: 'Vendor ID',
          class: 'col-fix'
        },
        {
          key: 'vendor_name',
          class: 'col-fix-vendor'
        },
        {
          key: 'vendor_pan_gst',
          class: 'col-fix'
        },
        // {
        //   key: 'vendor_gstn',
        //   label: 'Vendor GSTN',
        //   class: 'col-fix'
        // },
        {
          key: 'to_email',
          label: 'Vendor Email',
          class: 'col-fix'
        },
        {
          key: 'cc_email',
          label: 'Internal CC',
          class: 'col-fix'
        },
        {
          key: 'bcc_email',
          label: 'External CC',
          class: 'col-fix'
        },
        {
          key: 'is_active',
          label: 'Active',
          class: 'text-center'
        }
      ],
      selectAllCheckBox: false,
      selectedRow: [],
      showHistoryBtn: false,
      vendorId: 0,
      showExcelUploadModal: false,
      payloadData: [],
      userId: null,
      parent_value_set_id: null,
      showAddEmailSetupModal: null,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      }
    };
  },
  watch: {
    currentPage: function() {
      this.getVendorEmails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getVendorEmails();
    },
    emailSetupType() {
      this.getVendorEmails();
    }
  },
  validations: {
    emailSetupType: {
      text: { required }
    },
    legalEntity: {
      text: { required }
    }
  },
  mounted() {
    this.userId = this.$store.state.auth.userId;
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'upload') {
          this.showExcelUploadModal = true;
        }
        if (actionName === 'add') {
          this.showAddEmailSetupModal = true;
        }
        // if (actionName === 'download') {
        //   this.loader = true;
        //   /**
        //    * @param(payload, 'action name', 'file name')
        //    */
        //   const downloadpayload = { ...this.downPayload };
        //   downloadpayload._limit = this.totalRows;
        //   this.downloadExcel.downloadData(
        //     downloadpayload,
        //     'gstMaster/vendorEmailSetup',
        //     'GST_EMAIL_SETUP',
        //     () => (this.loader = false)
        //   );
        // }
      }
    });
  },
  methods: {
    update() {
      const filterData = this.vendorEmailData.filter(data => data.selectBox);
      const itemDetails = filterData.map(elem => {
        return {
          vendor_id: elem.vendor_comm_type_id,
          vendor_pan_gst: elem.vendor_pan_gst,
          to_email: [
            {
              email_to_cc: 'TO',
              to_email: elem.to_email.join(',')
            }
          ],
          cc_email: [
            {
              email_to_cc: 'CC',
              cc_email: elem.cc_email.join(',')
            }
          ],
          bcc_email: [
            {
              email_to_cc: 'BCC',
              bcc_email: elem.bcc_email.join(',')
            }
          ],
          is_active: elem.is_active ? 'Y' : 'N',
          email_criteria: this.emailSetupType.value,
          vendor_name: elem.vendor_name,
          le_id: this.legalEntity.value,
          org_id: this.ouName.value
        };
      });
      // this.payloadData = [];
      // this.selectedRow.filter(el =>
      //   this.payloadData.push({
      //     vendor_email_id: el.vendor_email_id,
      //     vendor_email: el.vendor_email,
      //     internal_cc: el.internal_cc,
      //     external_cc: el.external_cc,
      //     last_updated_by: this.userId,
      //     active: el.active
      //   })
      // );
      // this.payloadData = this.selectedRow.map(ele => {
      //   ele.vendor_id = ele.vendor_comm_type_id;
      // });
      const payload = {
        action: 'some_action',
        data: itemDetails
      };
      this.loading = true;
      this.$store
        .dispatch('gstMaster/updateRemoveVendorEmail', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 201) {
            // alert('Updated Successfully');
            this.$bvToast.toast(response.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
            this.getVendorEmails();
          } else {
            this.$bvToast.toast(response.response.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // remove() {
    //   this.selectedRow.filter(el =>
    //     this.payloadData.push({
    //       vendor_email_id: el.vendor_email_id,
    //       vendor_email: el.vendor_email,
    //       internal_cc: el.internal_cc,
    //       external_cc: el.external_cc
    //     })
    //   );
    //   const payload = {
    //     action: 'REMOVE',
    //     data: this.payloadData
    //   };
    //   this.loading = true;
    //   this.$store
    //     .dispatch('gstMaster/updateRemoveVendorEmail', payload)
    //     .then(response => {
    //       if (response.status === 200) {
    //         alert('Removed Successfully');
    //       }
    //       this.loading = false;
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //     });
    // },
    selectBoxChecked(flag, index, item, vendorID) {
      this.vendorEmailData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.vendor_id !== vendorID
        );
      }

      if (this.selectedRow.length > 0) {
        this.showHistoryBtn = true;
      } else {
        this.showHistoryBtn = false;
      }
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.vendorEmailData = this.vendorEmailData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.vendorEmailData;
      } else {
        this.vendorEmailData = this.vendorEmailData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
        this.showHistoryBtn = false;
      }
    },
    openValueSetModal(vsetCode) {
      if (
        (vsetCode === 'EMAIL_SETUP_TYPE' && !this.legalEntity.text) ||
        (vsetCode === 'GST_OPERATING_UNIT' && !this.legalEntity.text)
      ) {
        this.$bvToast.toast('Please select Legal Entity', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        return;
      }
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.GST_OPERATING_UNIT) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GST_OPERATING_UNIT) {
        this.ouName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.EMAIL_SETUP_TYPE) {
        this.emailSetupType = {
          value: item.value_code,
          text: item.value_meaning
        };
        // this.changeDocType(this.emailSetupType.text);
      }
      // this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.emailSetupType.value) {
        this.emailSetupType = this.defaultValue;
      } else if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
      } else if (vsetCode === this.ouName.value) {
        this.ouName = this.defaultValue;
      } else if (vsetCode === 'vendName') {
        this.vendorName = null;
      } else if (vsetCode === 'panNum') {
        this.panNumber = null;
      } else if (vsetCode === 'gstNum') {
        this.gstNumber = null;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    convertToUpperCase(item, index, type) {
      if (type === 'panGst') {
        this.vendorEmailData[index].vendor_pan_gst = item.toUpperCase();
      } else if (type === 'vendorName') {
        this.vendorEmailData[index].vendor_name = item.toUpperCase();
      }
    },
    clear() {
      this.emailSetupType = this.defaultValue;
      this.legalEntity = this.defaultValue;
      this.ouName = this.defaultValue;
      this.vendorName = null;
      this.panNumber = null;
      this.gstNumber = null;
      this.activeFlag = true;
    },
    getVendorEmails() {
      // if (this.emailSetupType.value === 'PAN_NUMBER') {
      //   this.vendorEmailFields = [
      //     {
      //       key: 'selectBox',
      //       label: 'Select',
      //       stickyColumn: true,
      //       variant: 'info',
      //       class: 'stickyColumn col-sm-1'
      //     },
      //     {
      //       key: 'vendor_comm_type_id',
      //       label: 'Vendor ID',
      //       class: 'col-fix'
      //     },
      //     {
      //       key: 'vendor_name'
      //     },
      //     {
      //       key: 'vendor_pan_gst',
      //       class: 'col-fix'
      //     },
      //     {
      //       key: 'to_email',
      //       label: 'Vendor Email',
      //       class: 'col-fix'
      //     },
      //     {
      //       key: 'cc_email',
      //       label: 'Internal CC',
      //       class: 'col-fix'
      //     },
      //     {
      //       key: 'bcc_email',
      //       label: 'External CC',
      //       class: 'col-fix'
      //     },
      //     {
      //       key: 'is_active',
      //       label: 'Active',
      //       class: 'text-center'
      //     }
      //   ];
      // } else if (this.emailSetupType.value === 'GST_NUMBER') {
      //   this.vendorEmailFields = [
      //     {
      //       key: 'selectBox',
      //       label: 'Select',
      //       stickyColumn: true,
      //       variant: 'info',
      //       class: 'stickyColumn col-sm-1'
      //     },
      //     {
      //       key: 'vendor_comm_type_id',
      //       label: 'Vendor ID',
      //       class: 'col-fix'
      //     },
      //     {
      //       key: 'vendor_name'
      //     },

      //     {
      //       key: 'vendor_pan_gst',
      //       label: 'Vendor GSTN',
      //       class: 'col-fix'
      //     },
      //     {
      //       key: 'to_email',
      //       label: 'Vendor Email',
      //       class: 'col-fix'
      //     },
      //     {
      //       key: 'cc_email',
      //       label: 'Internal CC',
      //       class: 'col-fix'
      //     },
      //     {
      //       key: 'bcc_email',
      //       label: 'External CC',
      //       class: 'col-fix'
      //     },
      //     {
      //       key: 'is_active',
      //       label: 'Active',
      //       class: 'text-center'
      //     }
      //   ];
      // }
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          search_email_setup_type: this.emailSetupType.value,
          search_vendor_name: this.vendorName,
          search_pan_gst_number: this.panNumber,
          // search_gst_number: this.gstNumber,
          active_flag: this.activeFlag ? 'Y' : 'N',
          org_id: this.ouName.value,
          le_id: this.legalEntity.value
        };
        // this.downPayload = payload
        this.loading = true;
        this.$store
          .dispatch('gstMaster/vendorEmailSetup', payload)
          .then(response => {
            if (response.status === 200) {
              this.vendorEmailData = response.data.data.page;
              this.vendorEmailData.map(ele => {
                ele.is_active = ele.is_active === 'Y' ? true : false;
              });
              this.totalRows = response.data.data.total_elements;
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    validateEmailGrid(emailList) {
      for (let i = 0; i < emailList.length; i++) {
        if (emailList[i]) {
          if (
            !/^[a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,6}$/.test(
              emailList[i].toLowerCase()
            )
          ) {
            // alert('Wrong email format:' + ' ' + emailList[i]);
            this.$bvToast.toast('Wrong email format:' + ' ' + emailList[i], {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
            emailList[i] = null;
          }
        }
      }
    },
    showHideAddEmailSetupModal(flag) {
      this.showAddEmailSetupModal = flag;
    }
    // changeDocType(key) {
    //   if (key === 'Pan Number') {
    //     let indexOfTaxRate = this.vendorEmailFields.findIndex(
    //       field => field.key === 'vendor_gstn'
    //     );
    //     this.vendorEmailFields.splice(indexOfTaxRate, 1);
    //     const panField = {
    //       key: 'vendor_pan',
    //       class: 'col-fix'
    //     };
    //     this.vendorEmailFields.splice(indexOfTaxRate, 0, panField);
    //     indexOfTaxRate = 0;
    //   } else if (key === 'GST Number') {
    //     let indexOfTaxRate = this.vendorEmailFields.findIndex(
    //       field => field.key === 'vendor_pan'
    //     );
    //     this.vendorEmailFields.splice(indexOfTaxRate, 1);
    //     const gstField = {
    //       key: 'vendor_gstn',
    //       label: 'Vendor GSTN',
    //       class: 'col-fix'
    //     };
    //     this.vendorEmailFields.splice(indexOfTaxRate, 0, gstField);
    //     indexOfTaxRate = 0;
    //   }
    // }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
